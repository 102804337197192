<script>
import { formatDistanceToNow } from "date-fns";
import { UserNotificationCount, UserNotificationCountSubscription, UserNotifications, UserNotificationsSubscription } from "../operations.gql";
export default {
  name: "NotificationSubscription",
  data() {
    return {
      resultUserNotificationCount: {
        aggregate: {
          count: 0
        }
      },
      resultUserNotifications: [
        {
          id: "",
          icon: "",
          link: "",
          created_at: "",
          content: "",
          notice_status_value: ""
        }
      ]
    };
  },
  render: () => null,
  apollo: {
    resultUserNotificationCount: {
      query: UserNotificationCount,
      variables() {
        return {
          userId: this.$auth.user.id
        };
      },
      result({ data }) {
        if (data && data.resultUserNotificationCount && data.resultUserNotificationCount.aggregate) {
          this.$emit("updatedCount", data.resultUserNotificationCount.aggregate.count);
        }
      },
      subscribeToMore: {
        document: UserNotificationCountSubscription,
        variables() {
          return {
            userId: this.$auth.user.id
          };
        },
        updateQuery(previousResult, { subscriptionData }) {
          return subscriptionData.data;
        }
      },
      skip() {
        return !this.$auth.isAuthenticated;
      }
    },
    resultUserNotifications: {
      query: UserNotifications,
      variables() {
        return {
          userId: this.$auth.user.id
        };
      },
      result({ data }) {
        if (data && data.resultUserNotifications && data.resultUserNotifications.length > 0) {
          const n = data.resultUserNotifications.map(item => {
            //   id: "",
            //   icon: "",
            //   link: "",
            //   created_at: "",
            //   content: "",
            //   notice_status_value: "",
            //!!the icon and link are populated by the backend, but that logic isn't yet done, because we don't have sendgrid templates for these yet
            return {
              id: item.id,
              text: item.content,
              posted: formatDistanceToNow(new Date(item.created_at), { addSuffix: true }),
              eyebrow: "New",
              noticeStatus: item.notice_status_value,
              hasLink: !!item.link,
              linkContent: item.link
            };
          });
          this.$emit("updatedCollection", n);
        }
      },
      subscribeToMore: {
        document: UserNotificationsSubscription,
        variables() {
          return {
            userId: this.$auth.user.id
          };
        },
        updateQuery(previousResult, { subscriptionData }) {
          return subscriptionData.data;
        }
      },
      skip() {
        return !this.$auth.isAuthenticated;
      }
    }
  }
};
</script>
