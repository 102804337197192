export const ALREADY_EXISTS = "Already Exists";
export const ALREADY_DELETED = "Already Deleted";
export const ALREADY_PUBLISHED = "Already Published";
export const CANCELLED = "Cancelled";
export const DELETE_FAILED = "Delete Failed";
export const DELETED = "Deleted";
export const EMPTY_FIELD = "Empty Field";
export const EQUAL = "Must Equal";
export const EXPIRED = "Expired";
export const EXTERNAL = "External System Failed";
export const HISTORY_SUCCESS_FAILED = "Failed to Insert History Success";
export const HISTORY_FAILURE_FAILED = "Failed to Insert History Failure";
export const INSERT_FAILED = "Insert Failed";
export const INVALID_ARGS = "Invalid Argument(s)";
export const INVALID_FORMAT = "Invalid Format";
export const INVALID_LENGTH = "Invalid Length";
export const LOCKED = "Prompt Locked";
export const MIGRATED = "Event Already Migrated";
export const NO_ACCESS = "No Access";
export const NOT_BELONG = "Does Not Belong";
export const NOT_DRAFT = "Must Have Draft Status";
export const NOT_EQUAL = "Must Not Equal";
export const NOT_EXIST = "Does Not Exist";
export const NOT_MIGRATED = "Event Not Migrated";
export const NOT_PUBLISHED = "Event Not Published";
export const NOT_SUPPORTED = "Not Supported";
export const NUMBER_TOO_LARGE = "Number Too Large";
export const NUMBER_TOO_SMALL = "Number Too Small";
export const PASSWORD = "Invalid Login";
export const PERMISSION = "Insufficient Permission";
export const TOO_EARLY = "Too Early";
export const TOO_LATE = "Too Late";
export const UNLOCKED = "Prompt Unlocked";
export const UNSPECIFIED = "Unspecified";
export const UNSUBSCRIBED = "Unsubscribed";
export const UNVERIFIED = "Unverified";
export const UPDATE_FAILED = "Update Failed";
export const UPLOAD_FAILED = "Upload Failed";
export const DEFAULT_FALLBACK = "Default"; //not an error returned by the backend

export const fallbackErrorMessage = "An error occurred. Please try again or contact support if the issue persists.";

// errors {
//   hierarchy
//   exceptionType
//   reason
//   message
// }

export function validationFailReasons(errors) {
  //a top-level ValidationException is always an ugly aggregate error and never the one we want to display
  //it will always have at least one child ValidationException that is accompanied by a reason that an individual method
  //there should never be another type of exception returned with a ValidationException
  //we must examine to determine how to react and/or message the user
  //so here we are just returning a list of all the actual validation error reasons
  let result = [];

  if (errors.length === 0) {
    return result;
  }

  if (errors[0].exceptionType !== "ValidationException") {
    return result;
  }

  for (var i = 1; i < errors.length; i++) {
    if (errors[i].exceptionType !== "ValidationException") {
      continue; //should never happen
    }
    if (errors[i].hierarchy.charAt(0) !== "0") {
      continue; //should never happen
    }
    result.push(errors[i].reason);
  }

  return result;
}

export function executionFailReasons(errors) {
  let result = [];

  if (errors.length === 0) {
    return result;
  }

  for (var i = 0; i < errors.length; i++) {
    if (errors[i].exceptionType === "ValidationException") {
      continue;
    }
    result.push(errors[i].reason);
  }

  return result;
}

export function validationFailReason(errors) {
  if (!errors || errors.length === 0) {
    return undefined;
  }

  let reason = undefined;
  let reasonsValidation = validationFailReasons(errors);
  if (reasonsValidation.length > 0) {
    reason = reasonsValidation[0];
  }
  return reason;
}

export function getErrorMessageValidation(errors, validationReasonMap) {
  if (!errors || errors.length === 0) {
    return undefined;
  }

  let msg = undefined;
  let reasonValidation = validationFailReason(errors);
  if (reasonValidation !== undefined) {
    msg = validationReasonMap.get(reasonValidation) ?? validationReasonMap.get(DEFAULT_FALLBACK);
  }

  return msg;
}

export function executionFailReason(errors) {
  if (!errors || errors.length === 0) {
    return undefined;
  }

  let reason = undefined;
  let reasonsExecution = executionFailReasons(errors);
  if (reasonsExecution.length > 0) {
    reason = reasonsExecution[0];
  }
  return reason;
}

export function getErrorMessageExecution(errors, executionReasonMap) {
  if (!errors || errors.length === 0) {
    return undefined;
  }

  let msg = undefined;
  let reasonExecution = executionFailReason(errors);
  if (reasonExecution !== undefined) {
    msg = executionReasonMap.get(reasonExecution) ?? executionReasonMap.get(DEFAULT_FALLBACK);
  }

  return msg;
}

export function getErrorMessage(errors, validationReasonMap, executionReasonMap) {
  if (!errors || errors.length === 0) {
    return undefined;
  }

  let msg =
    getErrorMessageValidation(errors, validationReasonMap) ??
    getErrorMessageExecution(errors, executionReasonMap) ??
    fallbackErrorMessage;

  return msg;
}
