import { DraftPost, CommitPost, EditPost, RemovePost, UnremovePost, MarkPost, MailUser, StampPost, UnstampPost } from "./operations.gql";
import * as BE from "@/utils/api/backendException.js";
import { hasuraClient } from "@/utils/graphql/apollo.js";
// import { Logger, DEBUG } from "@/utils/plugins/logging";
import { call } from "@/utils/api/base.js";

// const $log = new Logger("DiscussionApi", { level: DEBUG });

export async function draftPost(channelId, postContent, parentPostId = null, postId = null) {
  if (!postContent) return;
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "Drafted post must have text."],
    [BE.PERMISSION, "You don't have permission to draft this post."],
    [BE.NOT_EXIST, "Post not found."],
    [BE.NUMBER_TOO_LARGE, "The number provided is too large."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.NOT_EXIST, "Post not found."],
    [BE.INSERT_FAILED, "Error drafting the post."],
    [BE.UPDATE_FAILED, "Error updating the draft post."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: DraftPost,
        variables: {
          bodyJson: postContent,
          channelId: channelId,
          parentPostId,
          postId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function commitPost(postId, postContent = null) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "Commited post must have text"],
    [BE.ALREADY_EXISTS, "A post with this ID already exists."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.NOT_EXIST, "Post not found."],
    [BE.UPDATE_FAILED, "Error committing the post."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: CommitPost,
        variables: {
          bodyJson: postContent,
          postId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function editPost(postId, postBody) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "Edited post must have text."],
    [BE.PERMISSION, "You don't have permission to edit this post."],
    [BE.NOT_EXIST, "Post not found."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.NOT_EXIST, "Post not found."],
    [BE.UPDATE_FAILED, "Error updating the post."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: EditPost,
        variables: {
          bodyJson: postBody,
          postId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function removePost(postId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "Removed post cannot be empty."],
    [BE.PERMISSION, "You don't have permission to remove this post."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.DELETE_FAILED, "Error deleting the post."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: RemovePost,
        variables: {
          postId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function unremovePost(postId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "Unremoved post cannot be empty."],
    [BE.PERMISSION, "You don't have permission to unremove this post."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.DELETE_FAILED, "Error unremoving the post."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UnremovePost,
        variables: {
          postId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function markPost(postId, userId, noticeStatusValue) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "Post ID must not be empty."],
    [BE.PERMISSION, "You don't have permission to perform this action."],
    [BE.INVALID_ARGS, "Invalid notice status."],
    [BE.NOT_EXIST, "Post user not found."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error updating the post."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: MarkPost,
        variables: {
          postId,
          userId,
          noticeStatusValue
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function stampPost(postId, userId, reactionStampValue) {
  const validationReasonMap = new Map([
    [BE.PERMISSION, "You don't have permission to perform this action."],
    [BE.NOT_EXIST, "Post or User not found."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Error inserting the post reaction."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: StampPost,
        variables: {
          postId,
          userId,
          reactionStampValue
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function unstampPost(postId, userId, reactionStampValue) {
  const validationReasonMap = new Map([
    [BE.PERMISSION, "You don't have permission to perform this action."],
    [BE.NOT_EXIST, "Post or User not found."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.DELETE_FAILED, "Error removing the post reaction."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UnstampPost,
        variables: {
          postId,
          userId,
          reactionStampValue
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function mailUser(title = null, bodyJson, userId, parentPostId = null) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "The userId was not found."],
    [BE.NOT_EQUAL, "You can not message yourself."],
    [BE.NOT_EXIST, "The thread, channel, or post could not be found."],
    [BE.NUMBER_TOO_LARGE, "The thread depth of this message is too large."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "The message failed to be added."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: MailUser,
        variables: {
          title,
          bodyJson,
          userId,
          parentPostId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}
