<script>
import { UserMailCount, UserMailCountSubscription } from "../operations.gql";
export default {
  name: "MailSubscription",
  data() {
    return {
      resultUserMailCount: {
        aggregate: {
          count: 0
        }
      }
    };
  },
  render: () => null,
  apollo: {
    resultUserMailCount: {
      query: UserMailCount,
      variables() {
        return {
          userId: this.$auth.user.id
        };
      },
      result({ data }) {
        if (data && data.resultUserMailCount && data.resultUserMailCount.aggregate) {
          this.$emit("updated", data.resultUserMailCount.aggregate.count);
        }
      },
      subscribeToMore: {
        document: UserMailCountSubscription,
        variables() {
          return {
            userId: this.$auth.user.id
          };
        },
        updateQuery(previousResult, { subscriptionData }) {
          return subscriptionData.data;
        }
      },
      skip() {
        return !this.$auth.isAuthenticated;
      }
    }
  }
};
</script>
