import { getInstance } from "./index";
// import { Logger, INFO } from "../plugins/logging";

// const $log = new Logger("GUARD>ACCOUNT_FINISHED", { level: INFO });

export default (to, _, next) => {
  // $log.info("checking for finished account");
  if (to.name == "logout") {
    return next();
  }

  if (to.name == "CompleteAccount") {
    return next();
  }

  if (to.name == "CompleteAccountToken") {
    return next();
  }

  if (to.name == "DashboardProfile") {
    return next();
  }

  if (to.name == "Resource-CodeOfConduct") {
    return next();
  }

  if (to.name == "Resources-CodeOfConduct") {
    return next();
  }

  if (to.name == "Resource-TermsOfServices") {
    return next();
  }

  if (to.name == "Resources-TermsOfServices") {
    return next();
  }

  const auth = getInstance();

  const fn = () => {
    if (auth.isAuthenticated && !auth.user.country_abbrev) {
      next({ name: "CompleteAccount", query: { targetUrl: to.fullPath } });
      return;
    } else {
      return next();
    }
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!auth.loading) return fn();

  // Watch for the loading property to change before we check isAuthenticated
  auth.$watch("loading", loading => {
    if (loading === false) return fn();
  });
};
