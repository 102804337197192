import { MarkInbox } from "./operations.gql";
import * as BE from "@/utils/api/backendException.js";
import { hasuraClient } from "@/utils/graphql/apollo.js";
// import { Logger, DEBUG } from "@/utils/plugins/logging";
import { call } from "@/utils/api/base.js";

// const $log = new Logger("NotificationsApi", { level: DEBUG });

export async function markInbox(inboxId, noticeStatusValue) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "Inbox ID must not be empty."],
    [BE.INVALID_ARGS, "Invalid notice status."],
    [BE.NOT_EXIST, "Inbox not found."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error updating the inbox."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: MarkInbox,
        variables: {
          inboxId,
          noticeStatusValue
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}