import * as BE from "./backendException.js";

export async function call(fn, validationReasonMap, executionReasonMap) {
  try {
    const response = await fn();
    //$log.info("call response", response);
    const errors = response.data.result.errors;
    if (errors?.length > 0) {
      return {
        success: false,
        validationFailReason: BE.validationFailReason(errors), //can be referenced by caller to override the default error message
        executionFailReason: BE.executionFailReason(errors), //can be referenced by caller to override the default error message
        error: BE.getErrorMessage(errors, validationReasonMap, executionReasonMap)
      };
    }
    return { success: true, result: response.data?.result };
  } catch (error) {
    return { success: false, result: null, validationFailReason: BE.DEFAULT_FALLBACK, executionFailReason: BE.DEFAULT_FALLBACK, error: BE.fallbackErrorMessage };
  }
}
