import gql from "graphql-tag";
import { hasuraClient } from "../graphql/apollo.js";
// import { Logger, INFO } from "../plugins/logging.js";

// const $log = new Logger("GUARD>EVENTORGANIZER", { level: INFO });

export default async (to, _, next) => {
  const fn = async () => {
    const { f: failure, r: reason } = to.query; // Check for failure and reason in query params. if there are none it wont pass them.

    // Grab either the next upcoming event for the series or last known event
    const { data: { event_series_by_pk: series } = {} } = await hasuraClient.query({
      query: gql`
        query NearestEventBySeries($id: String = "") {
          event_series_by_pk(id: $id) {
            id
            is_draft
            slug
            upcoming_event: event_occurrences(order_by: { starts_at: asc }, limit: 1, where: { ends_at: { _gte: "now" }, is_cancelled: { _eq: false } }) {
              id
            }
            past_event: event_occurrences(order_by: { starts_at: desc }, limit: 1, where: { ends_at: { _lt: "now" }, is_cancelled: { _eq: false } }) {
              id
            }
            chapter {
              slug
            }
          }
        }
      `,
      variables: {
        id: to.params.seriesId
      }
    });

    if (series && series.is_draft === false) {
      if (series.upcoming_event.length > 0) {
        return next({
          name: "Series",
          params: { chapterSlug: series.chapter.slug, seriesSlug: series.slug, eventId: series.upcoming_event[0].id },
          query: { f: failure, r: reason }
        });
      } else if (series.past_event.length > 0) {
        return next({
          name: "Series",
          params: { chapterSlug: series.chapter.slug, seriesSlug: series.slug, eventId: series.past_event[0].id },
          query: { f: failure, r: reason }
        });
      }
    }
    return next({ name: "404" });
  };

  return await fn();
};
