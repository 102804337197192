import { getInstance } from "./index";
// import { Logger, INFO } from "../plugins/logging";

// const $log = new Logger("GUARD>ACCOUNT_FINISHED", { level: INFO });

export default (to, _, next) => {
  // $log.info("checking for provisional login");
  const auth = getInstance();

  const fn = () => {
    if (!auth.isAuthenticated) {
      return next();
    } else if (!auth.user.is_provisional_login) {
      return next();
    } else {
      if (to.name === "SignIn") {
        return next();
      }
      if (to.name === "LogOut") {
        return next();
      }
      if (to.name === "ResetPassword") {
        return next();
      }
      if (to.name === "ResetPasswordToken") {
        return next();
      }
      if (to.name === "CompleteAccount") {
        return next();
      }
      if (to.name === "CompleteAccountToken") {
        return next();
      }
      next({ name: "SignIn", query: { targetUrl: to.fullPath } });
    }
  };
  // If loading has already finished, check our auth state using `fn()`
  if (!auth.loading) return fn();

  // Watch for the loading property to change before we check isAuthenticated
  auth.$watch("loading", loading => {
    if (loading === false) return fn();
  });
};
