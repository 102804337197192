import gql from "graphql-tag";
import { hasuraClient } from "../graphql/apollo.js";

const FIND_EVENT_BY_LEGACY_ID = gql`
  query FindEventByLegacyId($legacy_event_id: Int!) {
    event_occurrences(where: { legacy_event_id: { _eq: $legacy_event_id } }) {
      id
      event_series {
        id
        slug
        chapter {
          slug
        }
      }
    }
  }
`;

export default async (to, from, next) => {
  try {
    const legacyEventId = parseInt(to.params.event_id, 10);
    if (isNaN(legacyEventId)) {
      console.error("Invalid event_id:", to.params.event_id);
      return next({ name: "404" });
    }

    const { data } = await hasuraClient.query({
      query: FIND_EVENT_BY_LEGACY_ID,
      variables: { legacy_event_id: legacyEventId }
    });

    const event = data?.event_occurrences?.[0];

    if (event && event.event_series) {
      const { slug: seriesSlug, chapter } = event.event_series;
      const chapterSlug = chapter.slug;

      return next({
        name: "Series",
        params: {
          chapterSlug,
          seriesSlug,
          eventId: event.id
        }
      });
    } else {
      return next({ name: "404" });
    }
  } catch (error) {
    console.error("Error in eventLookup:", error);
    return next({ name: "404" });
  }
};
