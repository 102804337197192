import gql from "graphql-tag";
import { getInstance } from "./index";
import { hasuraClient } from "../graphql/apollo.js";
//import { Logger, INFO } from "../plugins/logging.js";

//const $log = new Logger("GUARD>EVENTORGANIZER", { level: INFO });

export default async (to, _, next) => {
  const authService = getInstance();

  const fn = async () => {
    const { isAuthenticated, isOrganizer, isAdmin, user } = authService;

    if (!isAuthenticated || (!isOrganizer && !isAdmin)) {
      return next(false);
    }

    if (isAdmin) {
      // Let admin in whether it's their event or not
      return next();
    }

    // Check if the organizer is an organizer for the event
    const { data: { event_occurrences_by_pk } = {} } = await hasuraClient.query({
      query: gql`
        query CheckEventOrganizer($event_occurrence_id: String!, $user_id: String!) {
          event_occurrences_by_pk(id: $event_occurrence_id) {
            event_series {
              event_subscriptions(where: { is_organizer: { _eq: true }, user_id: { _eq: $user_id } }) {
                id
              }
            }
          }
        }
      `,
      variables: {
        event_occurrence_id: to.params.eventId,
        user_id: user.id
      }
    });

    if (event_occurrences_by_pk?.event_series?.event_subscriptions?.length > 0) {
      return next();
    } else {
      return next(false);
    }
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) return fn();

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) return fn();
  });
};
