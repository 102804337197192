import gql from "graphql-tag";
import { hasuraClient } from "../graphql/apollo.js";
import seriesOnlyGuard from "./seriesOnlyGuard.js";

const FIND_EVENT_SERIES_BY_TEMPLATE_ID = gql`
  query FindSeriesByTemplateId($event_template_id: Int!) {
    event_series(where: { legacy_event_template_id: { _eq: $event_template_id } }) {
      id
    }
  }
`;

export default async (to, from, next) => {
  try {
    const eventTemplateId = parseInt(to.params.event_template_id, 10);
    if (isNaN(eventTemplateId)) {
      console.error("Invalid event_template_id:", to.params.event_template_id);
      return next({ name: "404" });
    }

    const { data } = await hasuraClient.query({
      query: FIND_EVENT_SERIES_BY_TEMPLATE_ID,
      variables: { event_template_id: eventTemplateId }
    });

    const series = data?.event_series?.[0];

    if (series) {
      return seriesOnlyGuard({ ...to, params: { ...to.params, seriesId: series.id } }, from, next);
    } else {
      return next({ name: "404" });
    }
  } catch (error) {
    console.error("Error in seriesLookup:", error);
    return next({ name: "404" });
  }
};
