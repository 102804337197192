import Vue from "vue";
import VueCookie from "vue-cookie";
import jwt_decode from "jwt-decode";
import { LoginByToken, Logout } from "../../features/users/operations.gql";
import { isPast } from "date-fns";

let instance;
const cookieNameToken = "suw_auth_token";

/** Returns the current instance of the Auth service */
export const getInstance = () => instance;

/** Creates an instance of our authentication provider. If one has already been created, it returns that instance */
export const useAuthService = options => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    name: "Auth",
    data() {
      return {
        loading: true,
        user: null,
        apolloClient: null,
        router: null,
        token: null
      };
    },
    computed: {
      isAuthenticated() {
        return !this.loading && this.user !== null && this.token != null && this.user.deleted_at === null;
      },
      isAdmin() {
        return this.isAuthenticated && this.user.role === "app_admin";
      },
      isOrganizer() {
        return this.isAuthenticated && this.user.role === "organizer";
      }
    },
    created() {
      this.apolloClient = options.apolloClient;
      this.router = options.router;
      this.refreshAccess();
    },
    methods: {
      async refreshAuthUser(refreshOptions) {
        //refreshOptions: { accessToken: string, user: object }
        let { accessToken, user } = refreshOptions || {};
        this.token = accessToken;
        this.user = user;
      },
      async refreshAccess(refreshOptions) {
        //refreshOptions: { redirectLocation: string | Location, refreshToken: string, accessToken: string, user: object }
        //redirectLocation: string: "https://someurl" | Location: { name: "some_route" } (see VueRouter for all Location attributes)
        let { redirectLocation, accessToken, refreshToken, user } = refreshOptions || {};
        if (accessToken && refreshToken && user) {
          if (refreshToken.length > 6) {
            //provisional tokens are length 6; do not set provisional tokens in cookie
            VueCookie.set(cookieNameToken, refreshToken, { expires: "30D", secure: "true", sameSite: "Strict" });
          }
          await this.refreshAuthUser({ accessToken: accessToken, user: user });
          if (redirectLocation) {
            this.router.push(redirectLocation);
          }
          return;
        }
        // fallback to cookies if refreshToken is not supplied
        refreshToken = refreshToken || VueCookie.get(cookieNameToken);

        if (!refreshToken) {
          this.loading = false;
          return;
        }

        try {
          const response = await this.apolloClient.query({
            query: LoginByToken,
            fetchPolicy: "no-cache",
            variables: {
              token: refreshToken
            }
          });
          const errors = response.data.result.errors;
          if (errors?.length > 0) {
            if (errors?.length > 1) {
              this.logout({ redirectLocation: { name: "SignIn", params: { reason: errors[1].reason } } });
            } else {
              this.logout({ redirectLocation: { name: "SignIn", params: { reason: "unspecified" } } });
            }
          } else {
            const { accessToken, refreshToken, user } = response.data.result.value || {};
            if (refreshToken.length > 6) {
              //provisional tokens are length 6; do not set provisional tokens in cookie
              VueCookie.set(cookieNameToken, refreshToken, { expires: "30D", secure: "true", sameSite: "Strict" });
            }
            await this.refreshAuthUser({ accessToken: accessToken, user: user });
            if (redirectLocation) {
              if (this.router.currentRoute.name === "Home") {
                this.router.go();
              } else {
                this.router.push(redirectLocation);
              }
            }
          }
        } catch (err) {
          this.$log.error(err);
        } finally {
          this.loading = false;
        }
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved; called every dang second as the websocket keeps itself alive */
      async getTokenSilently() {
        if (this.token) {
          let decoded = jwt_decode(this.token);
          if (isPast(decoded.exp * 1000)) {
            this.token = null; //must reset this or else Hasura tries to do an authenticated call with our expired access token
            await this.refreshAccess(); //this sets this.token
          }
          return this.token;
        } else {
          await this.refreshAccess(); //this sets this.token
          return this.token;
        }
      },
      redirect(routeOptions) {
        //it's okay if we get an error that we're already here
        if (routeOptions) {
          this.router.push(routeOptions);
        } else {
          this.router.push({ name: "Home" });
        }
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(logoutOptions) {
        //logoutOptions: { redirectLocation: string | Location }
        let { redirectLocation } = logoutOptions || {};

        if (!this.isAuthenticated) {
          //...and here we delete the cookie for the refresh token...
          VueCookie.delete(cookieNameToken);
          //...and now remove them from the Vue instance
          this.token = null;
          this.user = null;
          this.redirect(redirectLocation);
          return;
        }

        //the mutation logs expires our refresh token on the server...
        this.apolloClient
          .mutate({
            mutation: Logout,
            variables: {
              userId: this.user.id
            }
          })
          .then(r => {
            if (r.data.result.errors) {
              this.$log.error(r.data.result.errors);
            }
          })
          .catch(e => {
            this.$log.error(e);
          })
          .finally(() => {

            //...and here we delete the cookie for the refresh token...
            VueCookie.delete(cookieNameToken);
            //...and now remove them from the Vue instance
            this.token = null;
            this.user = null;
            this.apolloClient.clearStore(); //reset the user's cache entirely, without refetching active queries
            // HACK - I wanted to be able to log out without a redirect
            if (redirectLocation.name === "none") return;
            this.redirect(redirectLocation);
          });
        return;
      }
    }
  });
  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const AuthPlugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuthService(options);
  }
};
