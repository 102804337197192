<script>
import { SuawSnackbar } from "@suaw/suaw-component-library";

export default {
  name: "GlobalSnackbar",
  components: { SuawSnackbar },
  data() {
    return {
      snackbarMessage: null,
      snackbarType: null
    };
  },
  created() {
    this.$root.$on("universal-error-message", msg => {
      this.showSnackbar(msg, "critical");
    });
    this.$root.$on("universal-success-message", msg => {
      this.showSnackbar(msg, "success");
    });
    this.$root.$on("universal-neutral-message", msg => {
      this.showSnackbar(msg, "neutral");
    });
    this.$root.$on("universal-info-message", msg => {
      this.showSnackbar(msg, "info");
    });
  },
  beforeDestroy() {
    this.$root.$off("universal-error-message");
    this.$root.$off("universal-success-message");
    this.$root.$off("universal-neutral-message");
    this.$root.$off("universal-info-message");
  },
  methods: {
    showSnackbar(message, type) {
      this.snackbarMessage = message;
      this.snackbarType = type;
    },
    resetSnackbar() {
      this.snackbarMessage = null;
      this.snackbarType = null;
    },
    onSnackbarConfirm() {
      this.resetSnackbar();
    },
    onSnackbarCancel() {
      this.resetSnackbar();
    }
  }
};
</script>

<template>
  <SuawSnackbar
    v-if="snackbarMessage"
    :text-message="snackbarMessage"
    :type="snackbarType"
    :has-timer="true"
    :timer-amount="8"
    :has-dismiss="false"
    @cancel-click="onSnackbarCancel"
    @action-click="onSnackbarConfirm"
  />
</template>
