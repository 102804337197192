import Vue from "vue";

export function copyright(value) {
  return value.replaceAll("®", '<sup style="font-size: 25px">®</sup>');
}
Vue.filter("copyright", value => copyright(value));

export function capitalizeFirstLetter(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}
Vue.filter("capitalizeFirstLetter", capitalizeFirstLetter);