<script>
import { SuawForm, SuawTextArea, SuawEmptyState, SuawModal, SuawSnackbar } from "@suaw/suaw-component-library";
import { required } from "vuelidate/lib/validators";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import * as UserApi from "../../api.js";

export default {
  name: "ComplaintModal",
  components: {
    SuawForm,
    SuawTextArea,
    SuawEmptyState,
    SuawModal,
    SuawSnackbar
  },
  data() {
    return {
      isComplaining: false,
      complaintForm: {
        context: "",
        userId: "",
        postId: "",
        comment: ""
      },
      snackbarMessage: null,
      snackbarType: null
    };
  },
  computed: {
    canComplain() {
      return this.$auth.isAuthenticated;
    }
  },
  created() {
    this.$root.$on("universal-complaint", complaintContext => {
      this.complaintForm.context = complaintContext.context;
      if (complaintContext.context === "Profile") {
        this.complaintForm.userId = complaintContext.userId;
      }
      if (complaintContext.context === "Avatar") {
        this.complaintForm.userId = complaintContext.userId;
      }
      if (complaintContext.context === "Post") {
        this.complaintForm.postId = complaintContext.postId;
      }
      this.isComplaining = true;
    });
  },
  beforeDestroy() {
    this.$root.$off("universal-complaint");
  },
  validations: {
    complaintForm: {
      comment: { required }
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    async reportComplaintProfile() {
      const { userId, comment } = this.complaintForm;
      const result = await UserApi.reportComplaintProfile(userId, comment);
      return result;
    },
    async reportComplaintAvatar() {
      const { userId, comment } = this.complaintForm;
      const result = await UserApi.reportComplaintAvatar(userId, comment);
      return result;
    },
    async reportComplaintPost() {
      const { postId, comment } = this.complaintForm;
      const result = await UserApi.reportComplaintPost(postId, comment);
      return result;
    },
    async onSubmitComplaint() {
      this.$v.complaintForm.$touch();
      if (this.$v.complaintForm.$invalid) {
        this.showSnackbar("The complaint form is invalid.", "critical");
        return;
      }
      let createResult = {
        success: false,
        error: "Invalid complaint context."
      };
      if (this.complaintForm.context === "Profile") {
        createResult = await this.reportComplaintProfile();
      }
      if (this.complaintForm.context === "Avatar") {
        createResult = await this.reportComplaintAvatar();
      }
      if (this.complaintForm.context === "Post") {
        createResult = await this.reportComplaintPost();
      }
      this.isComplaining = false;
      if (!createResult.success) {
        this.showSnackbar(createResult.error, "critical");
        return;
      }
      this.resetModal();
      this.$root.$emit("universal-success-message", "You have reported this user to our admin.");
    },
    async onCancelComplaint() {
      this.resetModal();
    },
    resetModal() {
      this.isComplaining = false;
      this.complaintForm.context = "";
      this.complaintForm.userId = "";
      this.complaintForm.postId = "";
      this.complaintForm.comment = "";
    },
    //!!code copied over from GlobalSnackbar, but could be removed if the z-index of the global snackbar ensured that it wasn't left in the darkness while this modal is open
    //and then instead of this.showSnackbar we would emit the universal-error-message and remove SuawSnackbar from here
    showSnackbar(message, type) {
      this.snackbarMessage = message;
      this.snackbarType = type;
    },
    resetSnackbar() {
      this.snackbarMessage = null;
      this.snackbarType = null;
    },
    onSnackbarConfirm() {
      this.resetSnackbar();
    },
    onSnackbarCancel() {
      this.resetSnackbar();
    }
  }
};
</script>

<template>
  <SuawModal
    v-if="isComplaining && canComplain"
    modal-type="confirmation"
    modal-title="Report User Behavior"
    :toggle-button-confirm="onSubmitComplaint"
    :toggle-button-cancel="onCancelComplaint"
  >
    <SuawForm>
      <template #form>
        <SuawSnackbar
          v-if="snackbarMessage"
          :text-message="snackbarMessage"
          :type="snackbarType"
          :has-timer="true"
          :timer-amount="8"
          :has-dismiss="false"
          @cancel-click="onSnackbarCancel"
          @action-click="onSnackbarConfirm"
        />
        <SuawTextArea id="complaintText" v-model="complaintForm.comment" :use-tip-tap="false" />
      </template>
    </SuawForm>
  </SuawModal>
  <SuawModal v-else-if="isComplaining" modal-type="custom" modal-size="small" :click-method="onCancelComplaint">
    <SuawEmptyState message="You must be logged in to report a user." />
  </SuawModal>
</template>
