import gql from "graphql-tag";
import { hasuraClient } from "../graphql/apollo.js";

const MANIFEST_EVENT_MUTATION = gql`
  mutation ManifestEvent($eventSeriesId: String!, $asOfDate: String!) {
    result: manifestEvent(cmd: { eventSeriesId: $eventSeriesId, asOfDate: $asOfDate }) {
      id
      errors {
        hierarchy
        exceptionType
        reason
        message
      }
    }
  }
`;

export default async (to, from, next) => {
  const { m: manifested, d: date, s: seriesId } = to.query;
  const { chapterSlug, seriesSlug } = to.params;
  // Allow navigation if m is "true" or not present. There are places that go to Series that dont deal with unmanifested events yet.
  if (!manifested) {
    return next();
  }

  try {
    // Parse the date from the query param
    const eventDate = new Date(date);

    // Subtract one day from the event date
    eventDate.setUTCDate(eventDate.getUTCDate() - 1);

    // Ensure the date is in 'YYYY-MM-DD' format
    const asOfDate = eventDate.toISOString().split("T")[0];

    // Call the manifestEvent mutation
    const { data } = await hasuraClient.mutate({
      mutation: MANIFEST_EVENT_MUTATION,
      variables: {
        eventSeriesId: seriesId,
        asOfDate: asOfDate
      }
    });

    const { id: newEventId } = data?.result || {};

    if (newEventId) {
      // If successful, navigate to Series with the new eventId
      return next({
        name: "Series",
        params: {
          chapterSlug,
          seriesSlug,
          eventId: newEventId
        }
      });
    }

    // If mutation fails, redirect to SeriesOnly with failure query and reason (r) manifest (m)
    return next({
      name: "SeriesOnly",
      params: { chapterSlug, seriesId },
      query: { f: "true", r: "m" }
    });
  } catch (error) {
    // On unexpected error, redirect to SeriesOnly with failure query and reason (r) unexpected (u)
    return next({
      name: "SeriesOnly",
      params: { chapterSlug, seriesId },
      query: { f: "true", r: "u" }
    });
  }
};
