import { getInstance } from "./index";
import router from "../../router";

export default (to, _, next) => {
  const authService = getInstance();

  const fn = () => {
    if (authService.isAuthenticated) {
      // Redirect authenticated users to DashboardEvents.
      return router.push({ name: "DashboardEvents" });
    }
    // Allow unauthenticated users to access Home.
    return next();
  };

  if (!authService.loading) return fn();

  authService.$watch("loading", loading => {
    if (!loading) fn();
  });
};
